import { jsx as _jsx } from "react/jsx-runtime";
import { ChevronDownSolid, XCircleSolid, XSmallIcon } from "../../icons";
import createSpacing from "@material-ui/core/styles/createSpacing";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
const ultravioletPaletteColorOptions = {
    50: "#F5F3FF",
    100: "#EAE4F5",
    200: "#DFD3F5",
    300: "#CBB3F5",
    400: "#A97EF2",
    500: "#8B5BDE",
    600: "#7E46DE",
    700: "#6933C4",
    800: "#7E46DE",
    900: "#502894",
};
const bluePaletteColorOptions = {
    50: "#EFF6FF",
    100: "#DBEAFE",
    200: "#BFDBFE",
    300: "#93C5FD",
    400: "#60A5FA",
    500: "#3B82F6",
    600: "#2563EB",
    700: "#1D4ED8",
    800: "#1E40AF",
    900: "#1E3A8A",
};
const greyPaletteColorOptions = {
    50: "#F9FAFB",
    100: "#F3F4F6",
    200: "#E5E7EB",
    300: "#D1D5DB",
    400: "#9CA3AF",
    500: "#6B7280",
    600: "#4B5563",
    700: "#374151",
    800: "#1F2937",
    900: "#111827",
};
const redPaletteColorOptions = {
    50: "#FEF2F2",
    100: "#FEE2E2",
    200: "#FECACA",
    300: "#FCA5A5",
    400: "#F87171",
    500: "#EF4444",
    600: "#DC2626",
    700: "#B91C1C",
    800: "#991B1B",
    900: "#7F1D1D",
};
const orangePaletteColorOptions = {
    50: "#FFF7ED",
    100: "#FFEDD5",
    200: "#FED7AA",
    300: "#FDBA74",
    400: "#FB923C",
    500: "#F97316",
    600: "#EA580C",
    700: "#C2410C",
    800: "#9A3412",
    900: "#7C2D12",
};
const yellowPaletteColorOptions = {
    50: "#FFFBEB",
    100: "#FEF3C7",
    200: "#FDE68A",
    300: "#FCD34D",
    400: "#FBBF24",
    500: "#F59E0B",
    600: "#D97706",
    700: "#B45309",
    800: "#92400E",
    900: "#78350F",
};
const greenPaletteColorOptions = {
    50: "#ECFDF5",
    100: "#D1FAE5",
    200: "#A7F3D0",
    300: "#6EE7B7",
    400: "#34D399",
    500: "#10B981",
    600: "#059669",
    700: "#047857",
    800: "#065F46",
    900: "#064E3B",
};
const tealPaletteColorOptions = {
    50: "#F0FDFA",
    100: "#CCFBF1",
    200: "#99F6E4",
    300: "#5EEAD4",
    400: "#2DD4BF",
    500: "#14B8A6",
    600: "#0D9488",
    700: "#0F766E",
    800: "#115E59",
    900: "#134E4A",
};
const cyanPaletteColorOptions = {
    50: "#ECFEFF",
    100: "#CFFAFE",
    200: "#A5F3FC",
    300: "#67E8F9",
    400: "#22D3EE",
    500: "#06B6D4",
    600: "#0891B2",
    700: "#0E7490",
    800: "#155E75",
    900: "#164E63",
};
const lightBluePaletteColorOptions = {
    50: "#F0F9FF",
    100: "#E0F2FE",
    200: "#BAE6FD",
    300: "#7DD3FC",
    400: "#38BDF8",
    500: "#0EA5E9",
    600: "#0284C7",
    700: "#0369A1",
    800: "#075985",
    900: "#0C4A6E",
};
const indigoPaletteColorOptions = {
    50: "#EEF2FF",
    100: "#E0E7FF",
    200: "#C7D2FE",
    300: "#A5B4FC",
    400: "#818CF8",
    500: "#6366F1",
    600: "#4F46E5",
    700: "#4338CA",
    800: "#3730A3",
    900: "#312E81",
};
const purplePaletteColorOptions = {
    50: "#F5F3FF",
    100: "#EDE9FE",
    200: "#DDD6FE",
    300: "#C4B5FD",
    400: "#A78BFA",
    500: "#8B5CF6",
    600: "#7C3AED",
    700: "#6D28D9",
    800: "#5B21B6",
    900: "#4C1D95",
};
const pinkPaletteColorOptions = {
    50: "#FDF2F8",
    100: "#FCE7F3",
    200: "#FBCFE8",
    300: "#F9A8D4",
    400: "#F472B6",
    500: "#EC4899",
    600: "#DB2777",
    700: "#BE185D",
    800: "#9D174D",
    900: "#831843",
};
const rosePaletteColorOptions = {
    50: "#FFF1F2",
    100: "#FFE4E6",
    200: "#FECDD3",
    300: "#FDA4AF",
    400: "#FB7185",
    500: "#F43F5E",
    600: "#E11D48",
    700: "#BE123C",
    800: "#9F1239",
    900: "#881337",
};
const navyPaletteColorOptions = {
    50: "#E9EAEB",
    100: "#B6B8BD",
    200: "#858892",
    300: "#545866",
    400: "#2F3545",
    500: "#0A1124",
    600: "#090F20",
    700: "#070C1B",
    800: "#020409",
    900: "#010103",
};
const gradientsPaletteColorOptions = {
    marine: "linear-gradient(94.76deg, #593FF9 13.61%, #09DCFF 84.59%)",
    coral: "linear-gradient(34.32deg, #FF469C 14.08%, #FFB747 95.87%)",
    ocean: "linear-gradient(88.03deg, #57FFD7 28.65%, #09DCFF 72.91%)",
};
const chartColorOptions = [
    greenPaletteColorOptions[700],
    greenPaletteColorOptions[500],
    greenPaletteColorOptions[300],
    lightBluePaletteColorOptions[300],
    lightBluePaletteColorOptions[400],
    lightBluePaletteColorOptions[500],
    purplePaletteColorOptions[300],
    purplePaletteColorOptions[400],
    purplePaletteColorOptions[600],
    yellowPaletteColorOptions[300],
    yellowPaletteColorOptions[400],
    orangePaletteColorOptions[300],
    orangePaletteColorOptions[500],
    redPaletteColorOptions[500],
    redPaletteColorOptions[700],
    greyPaletteColorOptions[500],
    pinkPaletteColorOptions[400],
    redPaletteColorOptions[900],
    redPaletteColorOptions[700],
    redPaletteColorOptions[500],
    redPaletteColorOptions[300],
    pinkPaletteColorOptions[900],
    pinkPaletteColorOptions[700],
    pinkPaletteColorOptions[500],
    pinkPaletteColorOptions[300],
    purplePaletteColorOptions[900],
    purplePaletteColorOptions[700],
    purplePaletteColorOptions[500],
    purplePaletteColorOptions[300],
    greyPaletteColorOptions[900],
    greyPaletteColorOptions[700],
    greyPaletteColorOptions[500],
    greyPaletteColorOptions[300],
];
const healthScoreChartColorOptions = {
    color: purplePaletteColorOptions[600],
    fillColor: purplePaletteColorOptions[50],
};
const basePaletteOptions = {
    primary: {
        light: ultravioletPaletteColorOptions[100],
        main: ultravioletPaletteColorOptions[600],
        dark: ultravioletPaletteColorOptions[700],
    },
    secondary: {
        main: ultravioletPaletteColorOptions[100],
        dark: ultravioletPaletteColorOptions[200],
    },
    text: {
        primary: greyPaletteColorOptions[900],
    },
    warning: {
        main: "#DDA200",
        light: "#FDF2D3",
        dark: "#C77500",
    },
    error: {
        main: redPaletteColorOptions[600],
    },
    ultraviolet: ultravioletPaletteColorOptions,
    grey: greyPaletteColorOptions,
    red: redPaletteColorOptions,
    orange: orangePaletteColorOptions,
    yellow: yellowPaletteColorOptions,
    green: greenPaletteColorOptions,
    teal: tealPaletteColorOptions,
    cyan: cyanPaletteColorOptions,
    lightBlue: lightBluePaletteColorOptions,
    blue: bluePaletteColorOptions,
    indigo: indigoPaletteColorOptions,
    purple: purplePaletteColorOptions,
    pink: pinkPaletteColorOptions,
    rose: rosePaletteColorOptions,
    gradients: gradientsPaletteColorOptions,
    navy: navyPaletteColorOptions,
    chart: chartColorOptions,
    healthScoreChart: healthScoreChartColorOptions,
    singleLineChart: {
        fillColor: greyPaletteColorOptions[100],
        color: greyPaletteColorOptions[600],
    },
    taskTrendChart: {
        color: ultravioletPaletteColorOptions[600],
        fillColor: ultravioletPaletteColorOptions[200],
    },
    assigneeColorMapping: [
        ultravioletPaletteColorOptions[900],
        ultravioletPaletteColorOptions[700],
        ultravioletPaletteColorOptions[500],
        bluePaletteColorOptions[900],
        bluePaletteColorOptions[700],
        bluePaletteColorOptions[500],
        redPaletteColorOptions[900],
        redPaletteColorOptions[700],
        redPaletteColorOptions[500],
        orangePaletteColorOptions[900],
        orangePaletteColorOptions[700],
        orangePaletteColorOptions[500],
        yellowPaletteColorOptions[900],
        yellowPaletteColorOptions[700],
        yellowPaletteColorOptions[500],
        greenPaletteColorOptions[900],
        greenPaletteColorOptions[700],
        greenPaletteColorOptions[500],
        tealPaletteColorOptions[900],
        tealPaletteColorOptions[700],
        tealPaletteColorOptions[500],
        cyanPaletteColorOptions[900],
        cyanPaletteColorOptions[700],
        cyanPaletteColorOptions[500],
        indigoPaletteColorOptions[900],
        indigoPaletteColorOptions[700],
        indigoPaletteColorOptions[500],
        lightBluePaletteColorOptions[900],
        lightBluePaletteColorOptions[700],
        lightBluePaletteColorOptions[500],
        purplePaletteColorOptions[900],
        purplePaletteColorOptions[700],
        purplePaletteColorOptions[500],
        pinkPaletteColorOptions[900],
        pinkPaletteColorOptions[700],
        pinkPaletteColorOptions[500],
        rosePaletteColorOptions[900],
        rosePaletteColorOptions[700],
        rosePaletteColorOptions[500],
        greyPaletteColorOptions[700],
        greyPaletteColorOptions[500],
    ],
};
const baseFocusShadow = "0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #6933C4";
const baseShadows = [
    "none",
    "0px 1px 2px rgba(0, 0, 0, 0.05)",
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
];
const fontFamily = `"Inter", sans-serif`;
const baseFontSize = 14;
const baseHtmlFontSize = 16;
const baseSpacingIncrement = 8;
const baseBorderRadius = 6;
const spacing = createSpacing(baseSpacingIncrement);
export const pxToRem = (pxToConvert) => `${pxToConvert / baseHtmlFontSize}rem`;
const baseTypographyOptions = {
    fontFamily,
    fontSize: baseFontSize,
    htmlFontSize: baseHtmlFontSize,
    overline: {
        fontWeight: 400,
        fontSize: pxToRem(10),
    },
    overlineLarge: {
        fontWeight: 500,
        fontSize: pxToRem(12),
    },
    caption: {
        fontWeight: 400,
        fontSize: pxToRem(13),
    },
    captionMedium: {
        fontWeight: 500,
        fontSize: pxToRem(13),
    },
    captionSemiBold: {
        fontWeight: 600,
        fontSize: pxToRem(13),
    },
    captionBold: {
        fontWeight: 700,
        fontSize: pxToRem(13),
    },
    button: {
        textTransform: "none",
        fontWeight: 500,
        fontSize: pxToRem(14),
    },
    body2: {
        fontWeight: 400,
        fontSize: pxToRem(14),
    },
    body1: {
        fontWeight: 400,
        fontSize: pxToRem(16),
    },
    subtitle4: {
        fontWeight: 400,
        fontSize: pxToRem(12),
    },
    subtitle4Medium: {
        fontWeight: 500,
        fontSize: pxToRem(12),
    },
    subtitle4SemiBold: {
        fontWeight: 600,
        fontSize: pxToRem(12),
    },
    subtitle4Bold: {
        fontWeight: 700,
        fontSize: pxToRem(12),
    },
    subtitle3: {
        fontWeight: 400,
        fontSize: pxToRem(14),
    },
    subtitle3Medium: {
        fontWeight: 500,
        fontSize: pxToRem(14),
    },
    subtitle3SemiBold: {
        fontWeight: 600,
        fontSize: pxToRem(14),
    },
    subtitle3Bold: {
        fontWeight: 700,
        fontSize: pxToRem(14),
    },
    subtitle2: {
        fontWeight: 400,
        fontSize: pxToRem(15),
    },
    subtitle2Medium: {
        fontWeight: 500,
        fontSize: pxToRem(15),
        lineHeight: 1.57,
    },
    subtitle2SemiBold: {
        fontWeight: 600,
        fontSize: pxToRem(15),
    },
    subtitle2Bold: {
        fontWeight: 700,
        fontSize: pxToRem(15),
    },
    subtitle1: {
        fontWeight: 400,
        fontSize: pxToRem(16),
    },
    subtitle1Medium: {
        fontWeight: 500,
        fontSize: pxToRem(16),
    },
    subtitle1SemiBold: {
        fontWeight: 600,
        fontSize: pxToRem(16),
    },
    subtitle1Bold: {
        fontWeight: 700,
        fontSize: pxToRem(16),
    },
    h6: {
        fontWeight: 400,
        fontSize: pxToRem(19),
    },
    h6Medium: {
        fontWeight: 500,
        fontSize: pxToRem(19),
    },
    h6SemiBold: {
        fontWeight: 600,
        fontSize: pxToRem(19),
    },
    h6Bold: {
        fontWeight: 700,
        fontSize: pxToRem(19),
    },
    h5: {
        fontWeight: 400,
        fontSize: pxToRem(23),
    },
    h5Medium: {
        fontWeight: 500,
        fontSize: pxToRem(23),
    },
    h5SemiBold: {
        fontWeight: 600,
        fontSize: pxToRem(23),
    },
    h5Bold: {
        fontWeight: 700,
        fontSize: pxToRem(23),
    },
    h4: {
        fontWeight: 400,
        fontSize: pxToRem(26),
    },
    h4Medium: {
        fontWeight: 500,
        fontSize: pxToRem(26),
    },
    h4SemiBold: {
        fontWeight: 600,
        fontSize: pxToRem(26),
    },
    h4Bold: {
        fontWeight: 700,
        fontSize: pxToRem(26),
    },
    h3: {
        fontWeight: 400,
        fontSize: pxToRem(18),
    },
    h3Medium: {
        fontWeight: 500,
        fontSize: pxToRem(18),
    },
    h3SemiBold: {
        fontWeight: 600,
        fontSize: pxToRem(18),
    },
    h3Bold: {
        fontWeight: 700,
        fontSize: pxToRem(18),
    },
    h2: {
        fontWeight: 400,
        fontSize: pxToRem(19),
    },
    h2Medium: {
        fontWeight: 500,
        fontSize: pxToRem(19),
    },
    h2SemiBold: {
        fontWeight: 600,
        fontSize: pxToRem(19),
    },
    h2Bold: {
        fontWeight: 700,
        fontSize: pxToRem(19),
    },
    h1: {
        fontWeight: 400,
        fontSize: pxToRem(23),
    },
    h1Medium: {
        fontWeight: 500,
        fontSize: pxToRem(23),
    },
    h1SemiBold: {
        fontWeight: 600,
        fontSize: pxToRem(23),
    },
    h1Bold: {
        fontWeight: 700,
        fontSize: pxToRem(23),
    },
};
export const baseTheme = {
    typography: baseTypographyOptions,
    shape: {
        borderRadius: baseBorderRadius,
    },
    spacing: baseSpacingIncrement,
    palette: basePaletteOptions,
    shadows: baseShadows,
    props: {
        MuiAutocomplete: {
            closeIcon: _jsx(XCircleSolid, { fontSize: "small" }),
            popupIcon: _jsx(ExpandMoreRoundedIcon, {}),
        },
        MuiSelect: {
            IconComponent: ChevronDownSolid,
        },
        MuiTooltip: {
            arrow: true,
        },
        MuiButtonBase: {
            disableRipple: true,
        },
        MuiTextField: {
            InputLabelProps: {
                shrink: false,
            },
        },
        MuiTabs: {
            indicatorColor: "primary",
            textColor: "primary",
        },
        MuiChip: {
            deleteIcon: _jsx(XSmallIcon, {}),
        },
        MuiRadio: {
            color: "primary",
        },
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                "#root": {
                    height: "100%",
                },
                html: {
                    height: "100%",
                    backgroundColor: "#F0F3F7",
                    fontSize: baseHtmlFontSize,
                },
                body: {
                    fontFamily,
                    height: "100%",
                    backgroundColor: "#F0F3F7",
                },
            },
        },
        MuiTableContainer: {
            root: {
                border: "1px solid #E5E7EB",
                background: "white",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.06)",
                borderRadius: "8px",
            },
        },
        MuiTableRow: {
            root: {
                height: "72px",
            },
            head: {
                height: "16px",
            },
        },
        MuiTableCell: {
            head: {
                fontSize: "13px",
                height: "40px",
                textTransform: "capitalize",
                fontWeight: 500,
                padding: "0px 24px",
                verticalAlign: "middle",
            },
        },
        MuiTabs: {
            root: {
                borderRadius: 0,
                background: "transparent",
                border: "none",
                borderBottom: "solid 1px #D1D5DB;",
            },
        },
        MuiTab: {
            root: {
                border: "none !important",
                minHeight: 45,
                minWidth: "unset !important",
                padding: "6px 15px",
            },
            textColorInherit: {
                "&$selected": {
                    fontWeight: 600,
                },
            },
            textColorPrimary: {
                "&$selected": {
                    fontWeight: 600,
                },
            },
            textColorSecondary: {
                "&$selected": {
                    fontWeight: 600,
                },
            },
        },
        MuiButtonBase: {
            root: {
                "&$focusVisible": {
                    boxShadow: baseFocusShadow,
                    zIndex: 1,
                },
            },
        },
        MuiButton: {
            root: {
                transition: "none",
                boxShadow: baseShadows[1],
                fontSize: pxToRem(14),
                fontWeight: 500,
                lineHeight: "17px",
                verticalAlign: "baseline",
                padding: "7.5px 16px",
            },
            iconSizeMedium: {
                "& > *:first-child": {
                    fontSize: pxToRem(16),
                },
            },
            sizeLarge: {
                fontSize: pxToRem(14),
                fontWeight: 500,
                lineHeight: "17px",
                padding: "9.5px 16px",
                verticalAlign: "baseline",
            },
            iconSizeLarge: {
                "& > *:first-child": {
                    fontSize: pxToRem(16),
                },
            },
            startIcon: {
                display: "unset",
                "& > *:first-child": {
                    verticalAlign: "bottom",
                },
            },
            endIcon: {
                display: "unset",
                "& > *:first-child": {
                    verticalAlign: "bottom",
                },
            },
            sizeSmall: {
                fontSize: pxToRem(12),
                fontWeight: 500,
                lineHeight: "17px",
                padding: "5.5px 12px",
                verticalAlign: "baseline",
            },
            iconSizeSmall: {
                "& > *:first-child": {
                    fontSize: pxToRem(16),
                },
            },
            contained: {
                boxShadow: baseShadows[1],
                "&:hover": {
                    boxShadow: baseShadows[1],
                },
                "&$focusVisible": {
                    boxShadow: `${baseFocusShadow}, ${baseShadows[1]}`,
                },
                "&:active": {
                    boxShadow: baseShadows[1],
                },
                "&$disabled": {
                    color: basePaletteOptions.grey[400],
                    border: "unset",
                    boxShadow: "none",
                    backgroundColor: basePaletteOptions.grey[100],
                },
            },
            containedSecondary: {
                color: basePaletteOptions.ultraviolet[700],
            },
            outlined: {
                color: basePaletteOptions.grey[700],
                backgroundColor: "#FFF",
                border: "unset",
                padding: "7.5px 16px",
                boxShadow: `inset 0px 0px 0px 1px ${basePaletteOptions.grey[300]}`,
                "&:hover": {
                    backgroundColor: basePaletteOptions.grey[50],
                },
                "&$focusVisible": {
                    boxShadow: `${baseFocusShadow}, inset 0px 0px 0px 1px ${basePaletteOptions.grey[300]}`,
                },
                "&$disabled": {
                    color: basePaletteOptions.grey[400],
                    border: "unset",
                    boxShadow: "none",
                    backgroundColor: basePaletteOptions.grey[100],
                },
            },
        },
        MuiSwitch: {
            root: {
                padding: 0,
                width: "44px",
                height: "24px",
            },
            switchBase: {
                padding: 0,
                margin: 2,
                color: "white",
            },
            track: {
                borderRadius: 24 / 2,
                backgroundColor: basePaletteOptions.grey[200],
                opacity: 1,
                "$checked:not($disabled):not($colorPrimary):not($colorSecondary) + &": {
                    opacity: 1.0,
                    backgroundColor: basePaletteOptions.ultraviolet[500],
                },
                "$checked:not($disabled)$colorPrimary + &": {
                    opacity: 1.0,
                    backgroundColor: basePaletteOptions.ultraviolet[500],
                },
                "$checked:not($disabled)$colorSecondary + &": {
                    opacity: 1.0,
                    backgroundColor: basePaletteOptions.ultraviolet[500],
                },
            },
            thumb: {
                boxSizing: "border-box",
                width: "20px",
                height: "20px",
                color: "white",
            },
        },
        MuiPaper: {
            root: {
                borderRadius: 8,
            },
            elevation1: {
                boxShadow: "none",
                filter: "drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06))",
            },
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: basePaletteOptions.grey[800],
                fontSize: pxToRem(13),
                fontWeight: 300,
                padding: spacing(1, 1.5),
                "& a": {
                    color: "#23d3ee",
                    textDecoration: "none",
                    "&:hover": {
                        textDecoration: "underline",
                    },
                },
            },
            arrow: {
                color: basePaletteOptions.grey[800],
            },
        },
        MuiOutlinedInput: {
            root: {
                // Note: this seems to be the only way to prevent border colour from changing when user hovers over a <Select /> component
                // and without using `!important`
                "&:hover:not(.Mui-focused):not(.Mui-disabled) .MuiSelect-root ~ .MuiOutlinedInput-notchedOutline": {
                    border: `1px solid ${basePaletteOptions.grey[300]}`,
                },
                "&.Mui-focused:not(.Mui-disabled) .MuiSelect-root ~ .MuiOutlinedInput-notchedOutline": {
                    border: `1px solid ${basePaletteOptions.grey[300]}`,
                },
                "&$focused ~ $notchedOutline": {
                    boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px ${basePaletteOptions.ultraviolet[500]}`,
                },
                "&$focused [class^='MuiSelect-root'] ~ $notchedOutline": {
                    borderColor: basePaletteOptions.grey[500],
                    borderWidth: 1,
                },
                boxShadow: baseShadows[1],
                fontSize: pxToRem(14),
                background: "white",
                "& fieldset": {
                    borderColor: basePaletteOptions.grey[300],
                },
                "&:hover": {
                    "&:not($focused):not($disabled) $notchedOutline": {
                        borderColor: basePaletteOptions.grey[500],
                    },
                },
                "&$disabled": {
                    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
                    color: basePaletteOptions.grey[200],
                    "& $notchedOutline": {
                        borderColor: basePaletteOptions.grey[200],
                    },
                    "&:hover": {
                        cursor: "not-allowed",
                        "& $notchedOutline": {
                            borderColor: basePaletteOptions.grey[200],
                        },
                    },
                    "& input": {
                        "&::placeholder": {
                            color: basePaletteOptions.grey[400],
                        },
                    },
                },
            },
            input: {
                "&$disabled": {
                    color: basePaletteOptions.grey[200],
                },
                paddingTop: 9.5,
                paddingBottom: 9.5,
                color: basePaletteOptions.grey[900],
                "&::placeholder": {
                    color: basePaletteOptions.grey[500],
                    opacity: 1,
                },
            },
            adornedEnd: {
                paddingRight: 0,
            },
            adornedStart: {
                color: basePaletteOptions.grey[500],
            },
        },
        MuiInputLabel: {
            outlined: {
                fontSize: pxToRem(14),
                transform: "none",
                position: "static",
                color: basePaletteOptions.grey[700],
                marginBottom: spacing(0.875),
                "&$focused": {
                    color: basePaletteOptions.grey[700],
                },
            },
        },
        MuiFormHelperText: {
            root: {
                fontSize: pxToRem(13),
                "&$error": {
                    "&:before": {
                        content: `""`,
                        background: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='2 2 20 20' ><path fill-rule='evenodd' clip-rule='evenodd' d='M21.6 12C21.6 17.3019 17.3019 21.6 12 21.6C6.69806 21.6 2.39999 17.3019 2.39999 12C2.39999 6.69806 6.69806 2.39999 12 2.39999C17.3019 2.39999 21.6 6.69806 21.6 12ZM13.2 16.8C13.2 17.4627 12.6627 18 12 18C11.3373 18 10.8 17.4627 10.8 16.8C10.8 16.1373 11.3373 15.6 12 15.6C12.6627 15.6 13.2 16.1373 13.2 16.8ZM12 5.99999C11.3373 5.99999 10.8 6.53725 10.8 7.19999V12C10.8 12.6627 11.3373 13.2 12 13.2C12.6627 13.2 13.2 12.6627 13.2 12V7.19999C13.2 6.53725 12.6627 5.99999 12 5.99999Z' fill='%23${basePaletteOptions.error.main.slice(1)}'></path></svg>")`,
                        display: "inline-block",
                        verticalAlign: "text-bottom",
                        width: spacing(2),
                        height: spacing(2),
                        marginRight: spacing(0.75),
                    },
                    fontSize: pxToRem(14),
                    color: basePaletteOptions.error.main,
                },
            },
            contained: {
                marginLeft: 0,
                marginRight: 0,
                marginTop: spacing(0.875),
            },
        },
        MuiSelect: {
            root: {
                "&:hover": {
                    backgroundColor: basePaletteOptions.grey[50],
                },
            },
            icon: {
                color: basePaletteOptions.grey[500],
            },
            select: {
                "&:focus": {
                    backgroundColor: basePaletteOptions.grey[100],
                    borderRadius: baseBorderRadius,
                },
            },
        },
        MuiIconButton: {
            root: {
                color: basePaletteOptions.grey[500],
            },
        },
        MuiAutocomplete: {
            input: {
                paddingTop: 9,
                paddingBottom: 9,
            },
            inputRoot: {
                "&[class*='MuiOutlinedInput-root']": {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            },
            option: {
                fontSize: pxToRem(14),
                marginTop: 1,
                marginBottom: 1,
                borderRadius: baseBorderRadius,
                paddingLeft: spacing(1),
                paddingRight: spacing(1),
                "&[aria-selected='true']": {
                    backgroundColor: basePaletteOptions.ultraviolet[100],
                    color: basePaletteOptions.ultraviolet[700],
                    "&:hover": {
                        backgroundColor: basePaletteOptions.ultraviolet[100],
                    },
                },
                "&:hover": {
                    background: basePaletteOptions.grey[200],
                },
            },
            popupIndicator: {
                color: basePaletteOptions.grey[500],
                borderLeftWidth: 1,
                borderLeftStyle: "solid",
                borderColor: basePaletteOptions.grey[300],
                borderRadius: 0,
                paddingTop: 6,
                paddingBottom: 6,
                minWidth: 37,
                marginLeft: 8,
                marginRight: -7,
                height: "100%",
                "& svg": {
                    fontSize: 21.5,
                },
            },
            popupIndicatorOpen: {
                borderLeftWidth: 0,
                borderRightWidth: 1,
                borderRightStyle: "solid",
            },
            endAdornment: {
                top: "unset",
                right: 5,
                height: "100%",
            },
            clearIndicator: {
                color: basePaletteOptions.grey[500],
            },
            groupLabel: {
                color: basePaletteOptions.grey[700],
                fontWeight: 500,
            },
            paper: {
                boxShadow: "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.05)",
                border: `1px solid ${basePaletteOptions.grey[200]}`,
                marginTop: spacing(1),
                paddingLeft: spacing(1),
                paddingRight: spacing(1),
                filter: "none",
            },
            tag: {
                backgroundColor: basePaletteOptions.ultraviolet[100],
                color: basePaletteOptions.ultraviolet[800],
                borderRadius: 4,
                fontWeight: 600,
                fontSize: pxToRem(12),
                lineHeight: pxToRem(14.5),
                height: 23,
                "& [class*=MuiChip-label]": {
                    padding: "1px 7px 0px 10px",
                },
                "& [class*=MuiChip-deleteIcon]": {
                    color: basePaletteOptions.ultraviolet[400],
                    height: 17,
                    width: 17,
                    "&:hover": {
                        color: basePaletteOptions.ultraviolet[500],
                    },
                },
            },
        },
        MuiMenu: {
            paper: {
                borderRadius: baseBorderRadius,
                boxShadow: "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
                paddingLeft: spacing(1),
                paddingRight: spacing(1),
                marginTop: spacing(1),
                border: `1px solid ${basePaletteOptions.grey[200]}`,
            },
        },
        MuiMenuItem: {
            root: {
                paddingLeft: spacing(1),
                paddingRight: spacing(1),
                marginTop: 1,
                marginBottom: 1,
                height: 35,
                borderRadius: baseBorderRadius,
                fontSize: pxToRem(14),
                "&:hover": {
                    background: basePaletteOptions.grey[200],
                },
                "&$selected": {
                    background: basePaletteOptions.ultraviolet[100],
                    color: basePaletteOptions.grey[700],
                    "&:hover": {
                        background: basePaletteOptions.ultraviolet[100],
                    },
                },
            },
        },
        MuiBackdrop: {
            root: {
                "@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)": {
                    backdropFilter: "blur(2px)",
                    WebkitBackdropFilter: "blur(2px)",
                    background: `${basePaletteOptions.grey[600]}B2`,
                },
            },
        },
        MuiDialogTitle: {
            root: {
                padding: spacing(2.5, 3, 1, 3),
                "& h2": {
                    color: basePaletteOptions.grey[900],
                    fontSize: pxToRem(19),
                    fontWeight: 600,
                },
            },
        },
        MuiDialogContent: {
            root: {
                padding: spacing(1, 3, 1.5, 3),
                overflow: "visible",
            },
        },
        MuiDialogActions: {
            root: {
                padding: spacing(1.5, 1.75, 2, 1.75),
                background: basePaletteOptions.grey[50],
            },
        },
        MuiRadio: {
            root: {
                padding: 0,
                height: 16,
                width: 16,
                color: basePaletteOptions.grey[300],
                "& svg": {
                    fontSize: 20,
                },
                "&:hover": {
                    backgroundColor: "transparent",
                },
                "&:not($checked):hover": {
                    color: basePaletteOptions.grey[600],
                },
            },
        },
        MuiCheckbox: {
            root: {
                padding: 0,
                height: 16,
                width: 16,
                "& svg": {
                    fontSize: 22,
                },
                "&:not($checked)": {
                    color: basePaletteOptions.grey[300],
                    "&:hover": {
                        color: basePaletteOptions.grey[600],
                    },
                },
            },
        },
        MuiFormControlLabel: {
            root: {
                marginLeft: 0,
            },
            label: {
                marginLeft: baseSpacingIncrement,
                fontWeight: 500,
                fontSize: pxToRem(14),
                lineHeight: pxToRem(17),
                color: basePaletteOptions.grey[700],
            },
        },
        MuiFormLabel: {
            root: {
                fontSize: pxToRem(14),
                lineHeight: pxToRem(17),
                fontWeight: 500,
                color: basePaletteOptions.grey[700],
                "&$focused": {
                    color: basePaletteOptions.grey[700],
                },
            },
        },
        MuiLink: {
            // TODO: Ignore primary/secondary colours and use blue as primary colour here instead.
            underlineHover: {
                textDecoration: "underline",
                cursor: "pointer",
                "&:hover": {
                    textDecoration: "none",
                },
            },
        },
        MuiListItem: {
            root: {
                "&$selected": {
                    backgroundColor: ultravioletPaletteColorOptions[200],
                    color: "black",
                    "&:hover": {
                        backgroundColor: ultravioletPaletteColorOptions[300],
                    },
                    "& svg": {
                        color: basePaletteOptions.primary.main,
                        fill: ultravioletPaletteColorOptions[300],
                    },
                },
            },
        },
    },
};
