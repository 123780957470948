import { ApolloError, useSession } from "@lumar/shared";
import React, { ReactNode } from "react";
import { useParams } from "react-router-dom";
import {
  CrawlContextDataQuery,
  useCrawlContextDataQuery,
  useCrawlContextAllReportCategoriesQuery,
  CrawlContextAllReportCategoriesQuery,
  useCrawlContextIndustryBenchmarksQuery,
} from "../../graphql";
import { useURLSearchParams } from "../../shared/routing/useURLSearchParams";
import { isNonNullable } from "../../shared/utils/isNonNullable";
import { getReportChange } from "../../shared/report-helpers/getReportChange";
import { getReportStatGUID } from "../../shared/report-helpers/reportStat";

type CrawlContextLastFinishedCrawl = NonNullable<
  NonNullable<CrawlContextDataQuery["getProject"]>["lastFinishedCrawl"]
>;

export type CrawlContextCrawl = Pick<
  CrawlContextLastFinishedCrawl,
  "id" | "rawID" | "incomplete" | "createdAt" | "crawlTypes"
>;

export type CrawlContextCrawlProject = CrawlContextLastFinishedCrawl["project"];

export type CrawlContextCrawlReportStat =
  CrawlContextLastFinishedCrawl["reportStats"][0] & {
    id: string;
    change: number | null;
  };

export type CrawlContextCrawlSegment = NonNullable<
  CrawlContextLastFinishedCrawl["crawlSegments"]
>["edges"][number]["node"]["segment"];

export type CrawlContextCrawlSetting =
  CrawlContextLastFinishedCrawl["crawlSetting"];

export interface CrawlReportCategoryTreeNode {
  code: string;
  name: string;
  hasHealthScore: boolean;
  reports: CrawlContextCrawlReportStat[];
  nodes: CrawlReportCategoryTreeNode[];
}

export interface CrawlReportCategoryListNode {
  parentCode?: string;
  code: string;
  name: string;
  hasHealthScore: boolean;
  reports: NonNullable<CrawlContextValue["data"]>["crawlReports"];
}

export interface CrawlContextValueHelpers {
  /**
   * Allows to select a crawl segment programatically.
   */
  selectCrawlSegment: (id: string | null) => void;
  /**
   * Returns crawl reports from a specific category.
   * It includes crawl reports of child categories!
   */
  getCrawlReportCategoryReportsList(
    reportCategoryCode: string,
    searchTerm?: string,
  ): CrawlContextCrawlReportStat[];
  /**
   * Returns error crawl reports from a specific category.
   * It includes crawl reports of child categories!
   */
  getCrawlReportCategoryErrorReportsList(
    reportCategoryCode: string,
    searchTerm?: string,
  ): CrawlContextCrawlReportStat[];
  /**
   * Returns changed crawl reports from a specific category.
   * It includes crawl reports of child categories!
   */
  getCrawlReportCategoryChangedReportsList(
    reportCategoryCode: string,
    searchTerm?: string,
  ): CrawlContextCrawlReportStat[];
  /**
   * Returns a specific node from crawl report category tree.
   */
  getCrawlReportCategoryTreeNode(
    crawlReportCategoryCode: string,
    searchTerm?: string,
  ): CrawlReportCategoryTreeNode | undefined;
  /**
   * Returns a specific node from crawl report category tree that includes changed crawl reports.
   */
  getChangedCrawlReportCategoryTreeNode(
    crawlReportCategoryCode: string,
    searchTerm?: string,
  ): CrawlReportCategoryTreeNode | undefined;
  /**
   * Returns a specific node from crawl report category tree that includes error crawl reports.
   */
  getErrorCrawlReportCategoryTreeNode(
    crawlReportCategoryCode: string,
    searchTerm?: string,
  ): CrawlReportCategoryTreeNode | undefined;
  /**
   * Returns a flat crawl report categories list with reports that match the search term.
   * It includes parent categories, even if they don't have matching reports but their descendants do!
   * The search term is tested against the report's name, code and its' tags.
   */
  getCrawlReportCategoriesListWithReportsMatchingSearchTerm(
    searchTerm: string,
  ): CrawlReportCategoryListNode[];
  /**
   * Returns the industry benchmark based on the provided category and industry codes.
   */
  getIndustryHealthScoreCategoryBenchmark(
    categoryCode?: string | null,
    industryCode?: string | null,
  ): number | undefined;
}

export interface CrawlContextValueData {
  /**
   * The currently viewed crawl.
   */
  crawl: CrawlContextCrawl;
  /**
   * Crawl setting of the currently viewed crawl.
   */
  crawlSetting?: CrawlContextCrawlSetting;
  /**
   * Segments in this crawl.
   */
  crawlSegments: CrawlContextCrawlSegment[];
  /**
   * Currently selected segment.
   */
  selectedSegment?: CrawlContextCrawlSegment;
  /**
   * Project this crawl belongs to.
   */
  crawlProject: CrawlContextCrawlProject;
  /**
   * All reports that exist in this crawl.
   */
  crawlReports: CrawlContextCrawlReportStat[];
  /**
   * Tree of all report categories that exist in this crawl.
   * Reports arrays are populated by reports that treat report category as primary.
   * If the primary report category is not specified in the report template, then first matching report category is associated.
   */
  crawlReportCategoriesTree: CrawlReportCategoryTreeNode[];
  /**
   * List of all report categories that exist in this crawl.
   * Reports arrays are populated by reports that treat report category as primary.
   * If the primary report category is not specified in the report template, then first matching report category is associated.
   */
  crawlReportCategoriesList: CrawlReportCategoryListNode[];
}

export interface CrawlContextValue {
  errors?: ApolloError[];
  loading: boolean;
  crawlNotFound: boolean;
  noCategoriesInTheSystem: boolean;
  helpers?: CrawlContextValueHelpers;
  data?: CrawlContextValueData;
}

const CrawlContext = React.createContext<CrawlContextValue | null>(null);

export function useCrawlContext(): CrawlContextValue {
  const context = React.useContext(CrawlContext);

  if (context === null) {
    throw new Error(
      "`useCrawlContext` has been called without CrawlContextProvider in the tree.",
    );
  }

  return context;
}

export function useCrawlContextHelpers(): NonNullable<
  CrawlContextValue["helpers"]
> {
  const context = React.useContext(CrawlContext);

  if (context === null) {
    throw new Error(
      "`useCrawlContextHelpers` has been called without CrawlContextProvider in the tree.",
    );
  }

  if (!context.helpers) {
    throw new Error(
      "`useCrawlContextHelpers` has been called when data wasn't loaded.",
    );
  }

  return context.helpers;
}

export function useCrawlContextData(): NonNullable<CrawlContextValue["data"]> {
  const context = React.useContext(CrawlContext);

  if (context === null) {
    throw new Error(
      "`useCrawlContextData` has been called without CrawlContextProvider in the tree.",
    );
  }

  if (!context.data) {
    throw new Error(
      "`useCrawlContextData` has been called when data wasn't loaded.",
    );
  }

  return context.data;
}

export function CrawlContextProvider(props: {
  children: ReactNode;
}): JSX.Element {
  const searchParams = useURLSearchParams();
  const { projectId } = useParams<{
    projectId: string;
  }>();
  const {
    isDeepCrawlAdminEnabled,
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();

  const isReportAllowedToBeShown = React.useCallback(
    (report: CrawlContextCrawlReportStat) => {
      if (isDeepCrawlAdminEnabled) {
        return true;
      }
      return !report.reportTemplate.admin;
    },
    [isDeepCrawlAdminEnabled],
  );

  const {
    data: crawlContextData,
    loading: crawlContextDataLoading,
    error: crawlContextDataError,
  } = useCrawlContextDataQuery({
    fetchPolicy: "cache-first",
    variables: {
      projectId,
      shouldQueryCrawlSegments: segmentationAvailable,
      includeAdminFields: !!isDeepCrawlAdminEnabled,
    },
  });

  const {
    data: allReportCategoriesData,
    loading: allReportCategoriesLoading,
    error: allReportCategoriesError,
    fetchMore: fetchMoreReportCategories,
  } = useCrawlContextAllReportCategoriesQuery({
    fetchPolicy: "cache-first",
  });

  // FIXME: Paginating breaks cache. Not an issue while writing this
  // but it will bite us later down the line.
  const reportCategoriesPageInfo =
    allReportCategoriesData?.getReportCategories.pageInfo;
  if (reportCategoriesPageInfo?.hasNextPage) {
    fetchMoreReportCategories({
      variables: {
        cursor: reportCategoriesPageInfo.endCursor,
      },
    });
  }

  const {
    data: industryBenchmarksData,
    loading: industryBenchmarksLoading,
    error: industryBenchmarksError,
    fetchMore: fetchMoreIndustryBenchmarks,
  } = useCrawlContextIndustryBenchmarksQuery({
    fetchPolicy: "cache-first",
  });

  const industryBenchmarksPageInfo =
    industryBenchmarksData?.getIndustryBenchmarks.pageInfo;
  if (industryBenchmarksPageInfo?.hasNextPage) {
    fetchMoreIndustryBenchmarks({
      variables: {
        cursor: industryBenchmarksPageInfo.endCursor,
      },
    });
  }

  const value = React.useMemo<CrawlContextValue>(() => {
    if (
      crawlContextDataLoading ||
      allReportCategoriesLoading ||
      industryBenchmarksLoading ||
      allReportCategoriesData?.getReportCategories.pageInfo.hasNextPage
    ) {
      return {
        loading: true,
        crawlNotFound: false,
        noCategoriesInTheSystem: false,
        errors: [crawlContextDataError, allReportCategoriesError].filter(
          isNonNullable,
        ),
      };
    }

    if (
      crawlContextDataError ||
      allReportCategoriesError ||
      industryBenchmarksError
    ) {
      return {
        loading: false,
        crawlNotFound: false,
        noCategoriesInTheSystem: false,
        errors: [
          crawlContextDataError,
          allReportCategoriesError,
          industryBenchmarksError,
        ].filter(isNonNullable),
      };
    }

    if (!crawlContextData?.getProject?.lastFinishedCrawl) {
      return {
        loading: false,
        crawlNotFound: true,
        noCategoriesInTheSystem: false,
      };
    }

    if (!allReportCategoriesData) {
      return {
        loading: false,
        crawlNotFound: false,
        noCategoriesInTheSystem: true,
      };
    }

    const crawlReports: CrawlContextCrawlReportStat[] =
      crawlContextData.getProject.lastFinishedCrawl.reportStats
        // Applying correct custom extraction report name and filtering out custom extraction reports
        // that are not defined in crawl setting.
        .map((reportStat) => {
          const id = getReportStatGUID(reportStat);
          const change = getReportChange(reportStat);

          if (reportStat.reportTemplate.code.includes("custom_extraction")) {
            const definedCustomExtraction =
              crawlContextData.getProject?.lastFinishedCrawl?.crawlSetting?.customExtractions?.find(
                (customExtraction) =>
                  customExtraction.reportTemplateCode ===
                  reportStat.reportTemplate.code,
              );

            if (definedCustomExtraction) {
              return {
                id,
                ...reportStat,
                change,
                reportTemplate: {
                  ...reportStat.reportTemplate,
                  name: definedCustomExtraction.label,
                },
              };
            }

            return null;
          }
          return { id, ...reportStat, change };
        })
        .filter(isNonNullable)
        .filter(isReportAllowedToBeShown);

    /* eslint-disable fp/no-mutating-methods*/
    const crawlErrorReports = crawlReports
      .filter(
        (report) =>
          (report.basic ?? 0) > 0 && (report.reportTemplateTotalSign ?? 0) < 0,
      )
      .sort(errorReportImpactComparator);

    const crawlChangedReports = crawlReports
      .filter((report) => {
        return !!report.change;
      })
      .sort(changedReportWeightComparator);
    /* eslint-enable fp/no-mutating-methods*/

    const crawlReportCategoriesTree = createCrawlReportCategoriesTree(
      allReportCategoriesData,
      crawlReports,
    );

    const crawlErrorReportCategoriesTree = createCrawlReportCategoriesTree(
      allReportCategoriesData,
      crawlErrorReports,
    );

    const crawlChangedReportCategoriesTree = createCrawlReportCategoriesTree(
      allReportCategoriesData,
      crawlChangedReports,
    );

    const crawlReportCategoriesList = createCrawlReportCategoriesList(
      crawlReportCategoriesTree,
    );

    return {
      loading: false,
      crawlNotFound: false,
      noCategoriesInTheSystem: false,
      helpers: {
        selectCrawlSegment(id: string | null): void {
          if (id) {
            searchParams.set("segmentId", id);
          } else {
            searchParams.delete("segmentId");
          }
          searchParams.apply();
        },

        getCrawlReportCategoryTreeNode(
          crawlReportCategoryCode: string,
          searchTerm?: string,
        ) {
          if (searchTerm) {
            const foundCrawlReports = getCrawlReportsMatchingSearchTerm(
              crawlReports,
              searchTerm,
            );

            const tree = createCrawlReportCategoriesTree(
              allReportCategoriesData,
              foundCrawlReports,
            );

            const node = getCrawlReportCategoryTreeNode(
              crawlReportCategoryCode,
              tree,
            );

            return node;
          }

          return getCrawlReportCategoryTreeNode(
            crawlReportCategoryCode,
            crawlReportCategoriesTree,
          );
        },

        getErrorCrawlReportCategoryTreeNode(
          crawlReportCategoryCode: string,
          searchTerm?: string,
        ) {
          if (searchTerm) {
            const foundCrawlReports = getCrawlReportsMatchingSearchTerm(
              crawlErrorReports,
              searchTerm,
            );

            const tree = createCrawlReportCategoriesTree(
              allReportCategoriesData,
              foundCrawlReports,
            );

            const node = getCrawlReportCategoryTreeNode(
              crawlReportCategoryCode,
              tree,
            );

            return node;
          }

          return getCrawlReportCategoryTreeNode(
            crawlReportCategoryCode,
            crawlErrorReportCategoriesTree,
          );
        },

        getChangedCrawlReportCategoryTreeNode(
          crawlReportCategoryCode: string,
          searchTerm?: string,
        ) {
          if (searchTerm) {
            const foundCrawlReports = getCrawlReportsMatchingSearchTerm(
              crawlChangedReports,
              searchTerm,
            );

            const tree = createCrawlReportCategoriesTree(
              allReportCategoriesData,
              foundCrawlReports,
            );

            const node = getCrawlReportCategoryTreeNode(
              crawlReportCategoryCode,
              tree,
            );

            return node;
          }

          return getCrawlReportCategoryTreeNode(
            crawlReportCategoryCode,
            crawlChangedReportCategoriesTree,
          );
        },

        getCrawlReportCategoriesListWithReportsMatchingSearchTerm(
          searchTerm: string,
        ) {
          const foundCrawlReports = getCrawlReportsMatchingSearchTerm(
            crawlReports,
            searchTerm,
          );

          const tree = createCrawlReportCategoriesTree(
            allReportCategoriesData,
            foundCrawlReports,
          );

          const list = createCrawlReportCategoriesList(tree);

          return list;
        },

        getCrawlReportCategoryReportsList(
          crawlReportCategoryCode: string,
          searchTerm?: string,
        ) {
          if (searchTerm) {
            const foundCrawlReports = getCrawlReportsMatchingSearchTerm(
              crawlReports,
              searchTerm,
            );

            const tree = createCrawlReportCategoriesTree(
              allReportCategoriesData,
              foundCrawlReports,
            );

            const node = getCrawlReportCategoryTreeNode(
              crawlReportCategoryCode,
              tree,
            );

            if (node) {
              return getCrawlReportCategoryTreeNodeReportsList(node);
            }

            return [];
          }

          const node = getCrawlReportCategoryTreeNode(
            crawlReportCategoryCode,
            crawlReportCategoriesTree,
          );

          if (node) {
            return getCrawlReportCategoryTreeNodeReportsList(node);
          }

          return [];
        },

        getCrawlReportCategoryErrorReportsList(
          crawlReportCategoryCode: string,
          searchTerm?: string,
        ) {
          if (searchTerm) {
            const foundCrawlReports = getCrawlReportsMatchingSearchTerm(
              crawlErrorReports,
              searchTerm,
            );

            const tree = createCrawlReportCategoriesTree(
              allReportCategoriesData,
              foundCrawlReports,
            );

            const node = getCrawlReportCategoryTreeNode(
              crawlReportCategoryCode,
              tree,
            );

            if (node) {
              // eslint-disable-next-line fp/no-mutating-methods
              return getCrawlReportCategoryTreeNodeReportsList(node).sort(
                errorReportImpactComparator,
              );
            }

            return [];
          }

          const node = getCrawlReportCategoryTreeNode(
            crawlReportCategoryCode,
            crawlErrorReportCategoriesTree,
          );

          if (node) {
            // eslint-disable-next-line fp/no-mutating-methods
            return getCrawlReportCategoryTreeNodeReportsList(node).sort(
              errorReportImpactComparator,
            );
          }

          return [];
        },

        getCrawlReportCategoryChangedReportsList(
          crawlReportCategoryCode: string,
          searchTerm?: string,
        ) {
          if (searchTerm) {
            const foundCrawlReports = getCrawlReportsMatchingSearchTerm(
              crawlChangedReports,
              searchTerm,
            );

            const tree = createCrawlReportCategoriesTree(
              allReportCategoriesData,
              foundCrawlReports,
            );

            const node = getCrawlReportCategoryTreeNode(
              crawlReportCategoryCode,
              tree,
            );

            if (node) {
              // eslint-disable-next-line fp/no-mutating-methods
              return getCrawlReportCategoryTreeNodeReportsList(node).sort(
                changedReportWeightComparator,
              );
            }

            return [];
          }
          const node = getCrawlReportCategoryTreeNode(
            crawlReportCategoryCode,
            crawlChangedReportCategoriesTree,
          );

          if (node) {
            // eslint-disable-next-line fp/no-mutating-methods
            return getCrawlReportCategoryTreeNodeReportsList(node).sort(
              changedReportWeightComparator,
            );
          }

          return [];
        },

        getIndustryHealthScoreCategoryBenchmark: (
          categoryCode,
          industryCode,
        ) => {
          if (!categoryCode || !industryCode) {
            return undefined;
          }

          return industryBenchmarksData?.getIndustryBenchmarks.edges.find(
            (benchmark) =>
              benchmark.node.reportCategoryCode === categoryCode &&
              benchmark.node.industryCode === industryCode,
          )?.node.healthScore;
        },
      },
      data: {
        crawl: {
          id: crawlContextData.getProject.lastFinishedCrawl.id,
          rawID: crawlContextData.getProject.lastFinishedCrawl.rawID,
          incomplete: crawlContextData.getProject.lastFinishedCrawl.incomplete,
          createdAt: crawlContextData.getProject.lastFinishedCrawl.createdAt,
          crawlTypes: crawlContextData.getProject.lastFinishedCrawl.crawlTypes,
        },
        crawlSetting:
          crawlContextData.getProject.lastFinishedCrawl.crawlSetting ??
          undefined,
        crawlSegments:
          crawlContextData.getProject.lastFinishedCrawl.crawlSegments?.edges.map(
            (edge) => edge.node.segment,
          ) ?? [],
        selectedSegment:
          crawlContextData.getProject.lastFinishedCrawl.crawlSegments?.edges.find(
            (edge) => edge.node.segment.id === searchParams.get("segmentId"),
          )?.node.segment,
        crawlProject: crawlContextData.getProject.lastFinishedCrawl.project,
        crawlReports,
        crawlReportCategoriesTree,
        crawlReportCategoriesList,
      },
    };
  }, [
    crawlContextDataLoading,
    allReportCategoriesLoading,
    industryBenchmarksLoading,
    allReportCategoriesData,
    crawlContextDataError,
    allReportCategoriesError,
    industryBenchmarksError,
    crawlContextData?.getProject?.lastFinishedCrawl,
    isReportAllowedToBeShown,
    searchParams,
    industryBenchmarksData?.getIndustryBenchmarks.edges,
  ]);

  return (
    <CrawlContext.Provider value={value}>
      {props.children}
    </CrawlContext.Provider>
  );
}

/**
 * Sorts error crawl reports by impact in descending order.
 */
function errorReportImpactComparator(
  a: CrawlContextCrawlReportStat,
  b: CrawlContextCrawlReportStat,
): number {
  return (
    (b.basic ?? 0) * (b.reportTemplateTotalWeight ?? 0) -
    (a.basic ?? 0) * (a.reportTemplateTotalWeight ?? 0)
  );
}

/**
 * Sorts changed reports by change weight in descending order.
 */
function changedReportWeightComparator(
  a: CrawlContextCrawlReportStat,
  b: CrawlContextCrawlReportStat,
): number {
  return (
    Math.abs(b.change ?? 0) * (b.reportTemplateTotalWeight ?? 0) -
    Math.abs(a.change ?? 0) * (a.reportTemplateTotalWeight ?? 0)
  );
}

function createCrawlReportCategoriesTree(
  allReportCategoriesData: CrawlContextAllReportCategoriesQuery,
  crawlReports: CrawlContextCrawlReportStat[],
  parentCode: string | null = null,
): CrawlReportCategoryTreeNode[] {
  /* eslint-disable fp/no-mutating-methods*/
  const parentNodes = allReportCategoriesData.getReportCategories.edges
    .filter((edge) => edge.node.parentCode === parentCode)
    .map((edge) => edge.node)
    .sort((a, b) => a.position - b.position);

  const wholeCrawlReportCategoryTree = parentNodes.map((parentNode) => {
    return {
      code: parentNode.code,
      name: parentNode.name || parentNode.code,
      hasHealthScore: parentNode.healthScore,
      reports: crawlReports
        .filter((crawlReport) => {
          if (crawlReport.reportTemplate.primaryReportCategoryCode) {
            return (
              crawlReport.reportTemplate.primaryReportCategoryCode ===
              parentNode.code
            );
          }
          return Boolean(
            crawlReport.reportTemplate.reportCategories.find(
              (reportCategory) => reportCategory.code === parentNode.code,
            ),
          );
        })
        .sort(
          (a, b) =>
            (a.reportTemplate.position ?? 0) - (b.reportTemplate.position ?? 0),
        ),
      nodes: createCrawlReportCategoriesTree(
        allReportCategoriesData,
        crawlReports,
        parentNode.code,
      ),
    };
  });

  return filterCrawlReportCategoriesTreeNodesThatIncludeReports(
    wholeCrawlReportCategoryTree,
  );
  /* eslint-enable fp/no-mutating-methods*/
}

function filterCrawlReportCategoriesTreeNodesThatIncludeReports(
  nodes: CrawlReportCategoryTreeNode[],
): CrawlReportCategoryTreeNode[] {
  return nodes
    .map((node) => {
      const descendantNodesIncludingReports =
        filterCrawlReportCategoriesTreeNodesThatIncludeReports(node.nodes);

      if (
        descendantNodesIncludingReports.length > 0 ||
        node.reports.length > 0
      ) {
        return {
          ...node,
          nodes: descendantNodesIncludingReports,
        };
      }

      return null;
    })
    .filter(isNonNullable);
}

function getCrawlReportCategoryTreeNode(
  crawlReportCategoryCode: string,
  crawlReportCategoryTree: CrawlReportCategoryTreeNode[],
): CrawlReportCategoryTreeNode | undefined {
  const foundCategory = crawlReportCategoryTree.find(
    (node) => node.code === crawlReportCategoryCode,
  );

  if (!foundCategory) {
    const descendantNodes = crawlReportCategoryTree.flatMap(
      (node) => node.nodes,
    );

    if (descendantNodes.length === 0) {
      return;
    }

    return getCrawlReportCategoryTreeNode(
      crawlReportCategoryCode,
      descendantNodes,
    );
  }

  return foundCategory;
}

function createCrawlReportCategoriesList(
  crawlReportCategoriesTree: CrawlReportCategoryTreeNode[],
  parentCode?: string,
): CrawlReportCategoryListNode[] {
  return crawlReportCategoriesTree.flatMap((node) => {
    return [
      {
        name: node.name,
        code: node.code,
        hasHealthScore: node.hasHealthScore,
        reports: node.reports,
        parentCode: parentCode,
      },
      ...createCrawlReportCategoriesList(node.nodes, node.code),
    ];
  });
}

function getCrawlReportsMatchingSearchTerm(
  crawlReports: CrawlContextCrawlReportStat[],
  searchTerm: string,
): CrawlContextCrawlReportStat[] {
  return crawlReports.filter((crawlReport) => {
    const term = searchTerm.toLowerCase();

    const isNameMatching = crawlReport.reportTemplate.name
      .toLocaleLowerCase()
      .includes(term);

    if (isNameMatching) {
      return true;
    }

    const isCodeMatching = crawlReport.reportTemplate.code
      .toLowerCase()
      .includes(term);

    if (isCodeMatching) {
      return true;
    }

    const areTagsMatching = !!crawlReport.reportTemplate.tags?.find((tag) =>
      tag.toLocaleLowerCase().includes(term),
    );

    return areTagsMatching;
  });
}

function getCrawlReportCategoryTreeNodeReportsList(
  node: CrawlReportCategoryTreeNode,
): CrawlContextCrawlReportStat[] {
  return [
    ...node.reports,
    ...node.nodes.flatMap((node) =>
      getCrawlReportCategoryTreeNodeReportsList(node),
    ),
  ];
}
